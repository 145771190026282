a{
    text-decoration:none;
}

.breadcrumb {
    margin-bottom: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 10px;
    margin-bottom: 10px;
}

#diplomado div.row,
#situacaoRegistro div.row,
#dadosCurso div.row,
#iesEmissora div.row,
#iesRegistradora div.row,
#livroRegistro div.row {
    padding-bottom: 10px;
    font-size: 14x;
}

#diplomado,
#situacaoRegistro,
#dadosCurso,
#iesEmissora,
#iesRegistradora,
#livroRegistro,
#acoesRegistro,
#curso-endereco-autorizacao-reconhecimento{
    margin: 5px;
}

.g-recaptcha {
    display: inline-block;
}

.acoesRegistro-row {
    padding-top: 5px;
}

h5 {
    font-weight: normal;
}

.botaoHome {
    float: left;
    width: 230px;
    height: 120px;
    margin: 10px;
    vertical-align: middle;
}

.btn-list {
    margin: 2px;
}

.btn-filtro-consulta-formandos {
    padding-top: 25px;
}

.registro-novo-botoes-etapas {
    margin: 20px;
}
.btn-novo-registro {
    margin: 5px;
}
.react-datepicker-popper {
    z-index: 9999 !important;
}
.arr-right .breadcrumb-item + .breadcrumb-item::before {
    content: '›';
    vertical-align: top;
    color: #373799;
    font-size: 28px;
    line-height: 16px;
}
.breadcrumb li:last-child {
    color: #000000;
    text-decoration: none;
    font-weight: bolder;
}
.btn-titulacao {
    margin-top: 27px;
}
.hlidmt {
    min-height: 0px !important;
}

@media (max-width:500px) {  
    .btn-ver-todos-consulta-publica { margin-top: 5px; }  
}

@media (min-width:600px) {  
    .btn-consultar-consulta-publica { margin-right: 5px;}  
}

.list-group-item-no-border{
    border: 0px;
}

.fonte-cinza{
    color: gray;
}